<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="downloadItems.length"
        badge-classes="bg-warning alert"
        class="text-body"
        icon="DownloadCloudIcon"
        size="21"
      />
    </template>

    <vue-perfect-scrollbar
      v-if="downloadItems.length > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div
        v-for="(item, index) in downloadItems"
        :item="item"
        :index="index"
        :key="index + 1"
      >
        <b-media>
          <p class="media-heading">
            <span style="max-width: 300px" class="d-block float-left font-weight-bolder text-truncate">
              {{ item.original_filename }}
            </span>
            <span class="font-weight-bolder float-right"> ({{ item.progress }}%) </span>
          </p>
          <br>
          <b-progress
            :value="item.progress"
            variant="warning"
            class="progress-bar-warning mt-25"
            animated
          />
        </b-media>
      </div>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BProgress,
} from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BProgress,
  },
  computed: {
    ...mapState({
      downloadItems: (state) => state.downloads.downloadItems,
    }),
  },
  data() {
    return {};
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style scoped>
.alert {
  animation: alert 1s ease 0s 2 normal forwards;
  border-radius: 10rem;
}
@keyframes alert {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
</style>