<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0 text-uppercase">
          {{ userData.fullName || userData.username }}
        </p>
        <div v-if="userData.permissions && userData.permissions.length > 0">
          <span class="user-status text-uppercase text-truncate d-block" style="max-width: 200px"
            v-if="userData.permissions.length <= 2">
            {{ userData.permissions.join(", ") }}
          </span>
          <span id="permissions-list" class="user-status text-uppercase text-truncate d-block" style="max-width: 200px"
            v-else>
            {{ userData.permissions.length }} permissões
          </span>
          <b-popover target="permissions-list" placement="left" triggers="hover" :delay="{ show: 800, hide: 100 }">
            <div v-for="(permission, id) in userData.permissions" :key="id">
              <p style="max-width: 300px" class="m-0 text-truncate text-capitalize">
                {{ permission }}
              </p>
            </div>
          </b-popover>
        </div>
      </div>
      <b-avatar size="40" :src="userData.avatar" v-if="userData.avatar" variant="light-primary" badge
        class="badge-minimal" badge-variant="success">
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  VBPopover,
  BPopover
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import _authService from "@/services/auth-service";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VBPopover,
    BPopover
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  beforeCreate() { },
  methods: {
    async logout() {
      // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");

      this.$cookie.delete('.AspNetCore.IntraCookie');
      this.clearAllCookies();

      window.open(process.env.VUE_APP_ACCOUNTS_SAIR_URL, '_blank');

      setTimeout(() => {
        const authUrl = `${process.env.VUE_APP_API}/api/auth/logout`;
        window.location.href = authUrl;
      }, 2000);VUE_APP_ACCOUNTS_SAIR_URL

    },
    clearAllCookies() {
      // Get all cookies
      const cookies = document.cookie.split(";");

      // Loop through the cookies and delete each one
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }
    }
  },

}
</script>