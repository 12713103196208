<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Estratégia Educacional © {{ new Date().getFullYear() }}
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  created() {
    this.enviromentAPP = process.env.VUE_APP_ENVIROMENT;
  },
  data() {
    return {
      enviromentAPP: "",
    };
  },
};
</script>
