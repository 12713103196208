<template>
<div class="navbar-container content">
  <div class="d-flex align-items-center" style="padding: 0.8rem 1.4rem">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <appointment />
      <dark-Toggler class="d-none d-lg-block" />
      <downloads-dropdown v-if="showDownloads" />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>

  <!-- ToolBars -->
  <VideoPlayer />
  <ProgressBar />
  <Operation />
  <RecordingMedia />
  <OperatorBar />
  <ValidateUpload />
</div>
</template>

<script>
import { BLink, BNavbarNav, BAlert } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import DownloadsDropdown from "./components/DownloadsDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import appointment from "./components/Appointment";
import VideoPlayer from "@/components/toolbars/VideoPlayer.vue";
import ProgressBar from "@/components/toolbars/ProgressBar.vue";
import Operation from "@/components/toolbars/Operation.vue";
import OperatorBar from "@/components/toolbars/OperatorBar.vue";
import ValidateUpload from "@/components/toolbars/ValidateUpload.vue";
import RecordingMedia from "@/components/toolbars/RecordingMedia.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      env_name: process.env.VUE_APP_ENVIROMENT,
    };
  },
  components: {
    BLink,
    BNavbarNav,
    Bookmarks,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    appointment,
    BAlert,
    VideoPlayer,
    ProgressBar,
    Operation,
    RecordingMedia,
    DownloadsDropdown,
    OperatorBar,
    ValidateUpload
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      showDownloads: (state) => state.downloads.show
    })
  }
};
</script>

<style scoped>
.header-navbar .navbar-container {
  padding: 0px !important;
}
</style>