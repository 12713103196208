var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand"},[(!_vm.is_accounts)?_c('div',[(
                  !_vm.isVerticalMenuCollapsed ||
                  (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered)
                )?_c('span',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"100%","margin-left":"7px"},attrs:{"src":_vm.skin === 'light' ? _vm.appLogoImage : _vm.appLogoImageDark,"alt":"logo"}})],1):_c('span',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"22.5px","position":"absolute","top":"12px","left":"30px"},attrs:{"src":_vm.skin === 'light' ? _vm.appLogoCutImage : _vm.appLogoCutImageDark,"alt":"logo"}})],1)]):_vm._e()])],1),_c('li',{staticClass:"nav-item nav-toggle",staticStyle:{"position":"absolute","top":"0px","right":"15px"}},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"id":"tooltip","icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1),_c('b-tooltip',{attrs:{"target":"tooltip","placement":"right","triggers":"hover"}},[_vm._v(" Fixar menu lateral ")])],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.$utils.getNavMenus().filter(function (f) { return f.active; })}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }