<template>
  <div
    class="tool-bar d-flex align-items-center justify-content-center"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <b-form-datepicker
      style="max-width: 150px"
      v-model="dateSelected"
      :date-format-options="{
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }"
      locale="pt-BR"
      @input="filter"
    />

    <div class="d-flex align-items-center ml-2">
      <span class="mr-1">Operador:</span>
      <v-select
        v-model="operatorSelected"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="operators"
        autocomplete="off"
        :clearable="true"
        class="drop-custom"
        @input="filter"
      >
        <template #selected-option="{ label }">
          <div class="d-inline-block text-truncate" style="max-width: 165px">
            {{ label }}
          </div>
        </template>
      </v-select>
    </div>

    <div class="d-flex align-items-center ml-2">
      <span class="mr-1">Turno:</span>
      <v-select
        v-model="workshiftSelected"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="workshifts"
        autocomplete="off"
        :clearable="true"
        class="drop-custom"
      >
        <template #selected-option="{ label }">
          <div class="d-inline-block text-truncate" style="max-width: 165px">
            {{ label }}
          </div>
        </template>
      </v-select>
    </div>

    <b-button class="ml-2 btn-icon" variant="primary" @click="filterClear">
      <font-awesome-icon size="lg" icon="fa-solid fa-eraser" />
    </b-button>

    <div class="separator m-3"></div>
    
    <b-button variant="success" class="btn-icon" @click="newAppointment">
      <feather-icon size="16" icon="PlusIcon" />
    </b-button>

    <b-button
      style="position: relative"
      :variant="operatorSelected ? 'warning' : 'secondary'"
      :disabled="operatorSelected ? false : true"
      @click="listOperatorPendencies"
      class="ml-2"
    >
      Em Aberto <b-badge v-if="countPendencies > 0" class="notification" pill variant="danger">{{ countPendencies }}</b-badge>
    </b-button>


    <b-button
      style="position: relative"
      :variant="operatorSelected ? 'warning' : 'secondary'"
      :disabled="operatorSelected ? false : true"
      class="ml-2"
      @click="listOperatorReworks"
    >
      Retrabalhos <b-badge v-if="countReworks > 0" class="notification" pill variant="danger">{{ countReworks }}</b-badge>
    </b-button>

    <b-button-group class="ml-3">
      <b-button class="btn-icon" :variant="viewMode ? 'primary' : 'success'" @click="UPDATE_VIEW_MODE">
        <feather-icon size="16" icon="GridIcon" />
      </b-button>
      <b-button class="btn-icon" :variant="viewMode ? 'success' : 'primary'" @click="UPDATE_VIEW_MODE">
        <feather-icon size="16" icon="AlignJustifyIcon" />
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { BFormDatepicker, BButton, BButtonGroup, BBadge } from "bootstrap-vue";
import { mapState, mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";
import _operatorsService from "@/services/operators-service";
import _workshiftService from "@/services/audiovisual/work-shift-service";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BFormDatepicker,
    BButton,
    BButtonGroup,
    BBadge
  },
  data() {
    return {
      toolBarName: "operation",
      workshifts: [],
      operators: [],
    };
  },
  computed: {
    ...mapState({
      currentToolBar: (state) => state.toolbar.currentToolBar,
      currentDate: (state) => state.toolbar.operation.dateSelected,
      currentOperator: (state) => state.toolbar.operation.operatorSelected,
      currentWorkshift: (state) => state.toolbar.operation.workshiftSelected,
      viewMode: (state) => state.toolbar.operation.viewListMode,
      countReworks: (state) => state.toolbar.operation.countReworks,
      countPendencies: (state) => state.toolbar.operation.countPendencies
    }),
    dateSelected: {
      get() {
        return this.currentDate;
      },
      set(value) {
        this.UPDATE_DATE_SELECTED(value);
      },
    },
    operatorSelected: {
      get() {
        return this.currentOperator;
      },
      set(value) {
        this.UPDATE_OPERATOR_SELECTED(value);
      },
    },
    workshiftSelected: {
      get() {
        return this.currentWorkshift;
      },
      set(value) {
        this.UPDATE_WORKSHIFT_SELECTED(value);
      },
    },
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    },
  },
  created() {
    this.getOperators();
    this.getWorkShifts();
    this.dateSelected = this.getDate();
  },
  methods: {
    ...mapMutations({
      UPDATE_DATE_SELECTED: "toolbar/operation/UPDATE_DATE_SELECTED",
      UPDATE_OPERATOR_SELECTED: "toolbar/operation/UPDATE_OPERATOR_SELECTED",
      UPDATE_WORKSHIFT_SELECTED: "toolbar/operation/UPDATE_WORKSHIFT_SELECTED",
      UPDATE_VIEW_MODE: "toolbar/operation/UPDATE_VIEW_MODE",
    }),
    getDate() {
      const today = new Date();
      return (
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1) +
        "-" +
        (today.getDate() < 10 ? "0" + today.getDate() : today.getDate())
      );
    },
    getOperators() {
      _operatorsService
        .showOperatorsWithUser()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.operators = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          } else {
            this.$utils.toastError(
              "Notificação",
              "Nenhum operador encontrado."
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getWorkShifts() {
      _workshiftService
        .showAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.workshifts = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          } else {
            this.$utils.toastError("Notificação", "Nenhum período encontrado.");
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    filter() {
      this.$root.$emit("clearPendency");
      this.$root.$emit("operatorFilter");
    },
    filterClear() {
      this.operatorSelected = null;
      this.workshiftSelected = null;
      this.dateSelected = this.getDate();
      this.$root.$emit("clearPendency");
      this.filter();
    },
    newAppointment() {
      this.$root.$emit("newAppointment");
    },
    listOperatorPendencies() {
      this.$root.$emit("listOperatorPendencies");
    },
    listOperatorReworks() {
      this.$root.$emit("listOperatorReworks");
    },
  },
};
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  color: white;
}
.is-open {
  height: 60px;
  padding: 10px;
  overflow: visible;
}
.skin-light {
  background-color: #1990ff;
}
.skin-dark {
  background-color: #121212;
}
.drop-custom {
  background-color: #fff;
  color: #95939f;
  border-radius: 5px;
  width: 250px;
}
.separator {
  height: 40px;
  border-left: 2px solid;
}
.notification {
  position: absolute;
  top: -10px;
  right: -10px;
}
</style>