<template>
  <div
    class="tool-bar d-flex flex-row-reverse"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <b-card-text class="mt-50 mr-2"> Gravações 'Futura Transmissão' </b-card-text>
    <b-form-checkbox
      v-model="viewMode"
      class="mt-50 custom-control-success"
      switch
    >
      <span class="switch-icon-left">
        <feather-icon icon="EyeIcon" />
      </span>
      <span class="switch-icon-right">
        <feather-icon icon="EyeOffIcon" />
      </span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox, VBTooltip } from "bootstrap-vue";
import { mapState, mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      toolBarName: "validate-upload",
    };
  },
  computed: {
    ...mapState({
      currentToolBar: (state) => state.toolbar.currentToolBar,
      isTransmission: (state) => state.toolbar.validateUpload.is_transmission,
    }),
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    },
    viewMode: {
      get() {
        return this.isTransmission
      },
      set(_value) {
        this.CHANGE_TRANSMISSION_VIEW();
        this.$root.$emit('changeTransmissionView');
      }
    }
  },
  methods: {
    ...mapMutations({
      CHANGE_TRANSMISSION_VIEW:
        "toolbar/validateUpload/CHANGE_TRANSMISSION_VIEW",
    }),
  },
};
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  display: block;
  color: white;
}

.is-open {
  height: 60px;
  padding: 10px;
}

.skin-light {
  background-color: #1990ff;
}

.skin-dark {
  background-color: #121212;
}
</style>
