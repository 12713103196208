<template>
  <div
    class="tool-bar d-flex align-items-center justify-content-end"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <div class="d-inline-block">
      <b-input-group>
        <b-form-input placeholder="Buscar por assunto..." v-model="subject" />
        <b-input-group-append>
          <b-button @click="getRecordingBySubject" variant="success">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="d-inline-block">
      <b-button
        :disabled="!$utils.isPermited('permission.audiovisual.demands.upload.midia.create')"
        variant="success"
        class="mx-2"
        @click="newRecording"
      >
        Nova gravação
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend
} from "bootstrap-vue";
import { mapState, mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      toolBarName: "recording-media",
    };
  },
  computed: {
    ...mapState({
      currentToolBar: (state) => state.toolbar.currentToolBar,
      searchSubject: (state) => state.toolbar.operation.searchSubject,
    }),
    subject: {
      get() {
        return this.searchSubject;
      },
      set(value) {
        this.UPDATE_SEARCH_SUBJECT(value);
      },
    },
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      UPDATE_SEARCH_SUBJECT: "toolbar/operation/UPDATE_SEARCH_SUBJECT",
    }),
    newRecording() {
      this.$root.$emit("newRecording");
    },
    getRecordingBySubject() {
      this.$root.$emit("getRecordingBySubject");
    },
  },
};
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  color: white;
}
.is-open {
  height: 60px;
  padding: 10px;
}
.skin-light {
  background-color: #1990ff;
}
.skin-dark {
  background-color: #121212;
}
</style>
