<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newMessages"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
        <b-badge pill variant="light-success">
          {{ newMessages }} Novas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="messages.length > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <div
        v-for="(notification, index) in messages"
        :item="notification"
        :index="index"
        :key="index + 1"
        class="cursor-pointer"
        @click="notification.isNew = false"
      >
        <b-media>
          <!-- <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template> -->
          <div v-show="notification.isNew" class="new-icon"></div>
          <p class="media-heading">
            <span class="font-weight-bolder text-capitalize">
              {{ notification.title }}
            </span>
          </p>
          <small v-html="notification.content" class="notification-text"></small>
        </b-media>
      </div>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <b-form-checkbox :checked="true" switch />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>
    <p class="text-center m-3" v-else>
      Nenhuma nova notificação
    </p>
    <!-- <div class="form-group">
      <form>
        <div>
          <label for="userName">Your Name</label>
          <input type="text" name="Name" id="userName" v-model="userName" />
        </div>
        <div>
          <label for="userMessage">Message</label>
          <input
            type="text"
            name="Message"
            id="userMessage"
            v-model="userMessage"
          />
        </div>
        <button v-on:click="submitCard" type="button">Submit</button>
      </form>
       <ul v-for="(item, index) in messages" v-bind:key="index + 'itemMessage'">
        <li><b>Name: </b>{{ item.user }}</li>
        <li><b>Message: </b>{{ item.message }}</li>
      </ul> 
    </div> -->
    <!-- Cart Footer -->
    <li
      class="dropdown-menu-footer d-flex justify-content-between"
      :style="messages.length == 0 ? 'border-top: 1px solid #ebe9f1' : 'border-top: none'"
    >
      <b-button
        :disabled="messages && messages.length <= 0"
        variant="primary"
        block
        @click="makeAllRead"
        class="mr-1"
      >
        Marcar Todas Como Lida
      </b-button>
      <b-button
        :disabled="messages && messages.length <= 0"
        variant="danger"
        @click="clearMessages"
      >
        Limpar
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>
<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BTabs,
  BTab
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import * as signalR from "@aspnet/signalr";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BTabs,
    BTab
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userName: "",
      userMessage: "",
      connection: "",
      messages: [],
    };
  },
  computed: {
    newMessages() {
      let news = []
      this.messages.forEach(m => {
        if(m.isNew) {
          news.push(m)
        }
      });
      return news.length
    }
  },
  methods: {
    makeAllRead() {
      this.messages.forEach(m => {
        m.isNew = false
      })
    },
    getNotifications() {
      let storage = JSON.parse(localStorage.getItem('notifications'));
      this.messages = storage ? storage.notifications : []
    },
    clearMessages() {
      this.messages = []
    }
  },
  watch: {
    messages: {
      handler() {
        let notifications = { notifications: this.messages }
        localStorage.setItem('notifications', JSON.stringify(notifications));
      },
      deep: true
    }
  },
  created: function () {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_API + "/notification-hub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => `${localStorage.getItem("accessToken")}`,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.getNotifications()
  },
  mounted: function () {
    var thisVue = this;
    thisVue.connection.start();
    thisVue.connection.on("ReceiveMessage", function (res) {      
      thisVue.messages.unshift({
        title: res.subject,
        content: res.content,
        isNew: true
      });
      if(thisVue.messages.length > 15) {
        thisVue.messages.pop()
      }
    });
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
.new-icon {
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgb(0, 187, 0);
  border-radius: 5px;
  float: right;
}
</style>
