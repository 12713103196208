<template>
  <div
    class="tool-bar d-flex flex-row-reverse"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <b-button
      style="position: relative"
      variant="warning"
      class="ml-2"
      @click="listOperatorPendencies"
    >
      Em Aberto <b-badge v-if="countPendencies > 0" class="notification" pill variant="danger">{{ countPendencies }}</b-badge>
    </b-button>

    <b-button
      style="position: relative"
      variant="warning"
      class="ml-2"
      @click="listOperatorReworks"
    >
      Retrabalhos <b-badge v-if="countReworks > 0" class="notification" pill variant="danger">{{ countReworks }}</b-badge>
    </b-button>

    <b-button-group class="ml-3">
      <b-button class="btn-icon" :variant="viewMode ? 'primary' : 'success'" @click="UPDATE_VIEW_MODE">
        <feather-icon size="16" icon="GridIcon" />
      </b-button>
      <b-button class="btn-icon" :variant="viewMode ? 'success' : 'primary'" @click="UPDATE_VIEW_MODE">
        <feather-icon size="16" icon="AlignJustifyIcon" />
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { BButton, BBadge, BButtonGroup } from "bootstrap-vue";
import { mapState, mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BButton,
    BBadge,
    BButtonGroup
  },
  data() {
    return {
      toolBarName: "operator-bar",
    };
  },
  computed: {
    ...mapState({
      currentToolBar: (state) => state.toolbar.currentToolBar,
      viewMode: (state) => state.toolbar.operation.viewListMode,
      countReworks: (state) => state.toolbar.operation.countReworks,
      countPendencies: (state) => state.toolbar.operation.countPendencies
    }),
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      UPDATE_VIEW_MODE: "toolbar/operation/UPDATE_VIEW_MODE"
    }),
    listOperatorPendencies() {
      this.$root.$emit('listOperatorPendencies');
    },
    listOperatorReworks() {
      this.$root.$emit('listOperatorReworks');
    },
  }
};
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  display: block;
  color: white;
}
.is-open {
  height: 60px;
  padding: 10px;
}
.skin-light {
  background-color: #1990ff;
}
.skin-dark {
  background-color: #121212;
}
.notification {
  position: absolute;
  top: -10px;
  right: -10px;
}
</style>
