<template>
  <div>
    <b-button
      variant="info"
      style="margin-right: 20px"
      @click="appointment(true)"
      v-if="showScheduled"
    >
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span class="align-middle">Agendamento</span>
    </b-button>
    <!-- Botão escondido para desenvolvimento da funcionalidade -->
    <!-- <b-button
      variant="info"
      style="margin-right: 20px"
      @click="appointment(true)"
      v-if="showScheduledNew"
    >
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span class="align-middle">Agendamento - BETA</span>
    </b-button> -->
  </div>
</template>
<script>
import { methods } from "vue-echarts";
export default {
  data() {
    return {
      loading: false,
      showScheduled: false,
      showScheduledNew: false,
    };
  },
  created() {
    this.showScheduled = this.$utils.isPermited("permission.appointment.create");
    this.showScheduledNew = this.$utils.isPermited("permission.appointment.create") && this.$utils.isPermited("permission.appointment.appointment_new");
  },
  methods: {
    appointment(newAppointment = true) {
      if (newAppointment) {
        window.location.href = "/pedagogic/appointment/0";
      } else {
        window.location.href = "/pedagogic/appointment-new/0";
      }
    }
  }
};
</script>