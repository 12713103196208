<template>
  <div
    class="tool-bar"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <div class="d-flex justify-content-center align-items-center">
      <div class="aling-center">
        <b-button
          variant="flat-light"
          class="btn-icon rounded-circle"
          @click="playingChange(currentMediaId)"
        >
          <feather-icon :icon="playingIconChange(currentMediaId)" size="17" />
        </b-button>
      </div>
      <div
        style="width: 100%"
        class="aling-center pr-50 pl-50"
        v-if="currentVideo && currentVideo.pip"
      >
        <vue-slider
          v-model="currentTime"
          :min="0"
          :max="currentVideo.pip.duration"
          direction="ltr"
          :interval="1"
          :duration="0"
          class="vue-slider-light"
          :tooltip-formatter="formatterDurarion(currentVideo.pip.currentTime)"
          :tooltipStyle="{ color: 'black' }"
          :tooltip-placement="'right'"
          @change="currentTimeChange(currentVideo, currentMediaId)"
        />
      </div>
      <div class="aling-center mr-1" v-if="currentVideo && currentVideo.pip">
        <div class="d-flex justify-content-center align-items-center">
          <div class="aling-center">
            <b-button
              variant="flat-light"
              class="btn-icon rounded-circle"
              @click="volChange(currentVideo, currentMediaId, true)"
            >
              <feather-icon :icon="currentVideo.pip.currentVolIcon" size="17" />
            </b-button>
          </div>
          <div class="aling-center pr-50 pl-50">
            <vue-slider
              style="width: 60px"
              direction="ltr"
              :interval="0.1"
              :duration="0"
              :min="0"
              :max="1"
              class="vue-slider-light"
              :tooltip="'none'"
              v-model="currentVol"
              @change="volChange(currentVideo, currentMediaId)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import { mapState, mapMutations } from "vuex";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    VueSlider,
  },
  data() {
    return {
      toolBarName: "video-player",
    };
  },
  computed: {
    ...mapState({
      currentToolBar: (state) => state.toolbar.currentToolBar,
      currentVideo: (state) => state.toolbar.videoPlayer.videoElement,
      currentMediaId: (state) => state.toolbar.videoPlayer.videoId,
    }),
    currentTime: {
      get() {
        return this.currentVideo.pip.currentTime;
      },
      set(value) {
        this.UPDATE_CURRENT_TIME(value);
      },
    },
    currentVol: {
      get() {
        return this.currentVideo.pip.currentVol;
      },
      set(value) {
        this.UPDATE_CURRENT_VOL(value);
      },
    },
    lightMode() {
      return this.skin == "light" ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TIME: "toolbar/videoPlayer/UPDATE_CURRENT_TIME",
      UPDATE_CURRENT_VOL: "toolbar/videoPlayer/UPDATE_CURRENT_VOL",
    }),
    playingChange(id) {
      var media = document.getElementById(`media-${id}`);
      if (media) {
        if (media.paused) {
          media.play();
        } else {
          media.pause();
        }
      }
    },
    playingIconChange(id) {
      var media = document.getElementById(`media-${id}`);
      if (media) {
        if (media.paused) {
          return "PlayIcon";
        } else {
          return "PauseIcon";
        }
      }
      return "PlayIcon";
    },
    formatterDurarion(duration) {
      let hours = Math.floor(duration / 3600);
      let minutes = Math.floor((duration - hours * 3600) / 60);
      let seconds = Math.floor(duration % 60);

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
    currentTimeChange(item, id) {
      var media = document.getElementById(`media-${id}`);
      if (media) {
        media.currentTime = item.pip.currentTime;
      }
    },
    volChange(item, id, isMute = false) {
      var media = document.getElementById(`media-${id}`);
      if (media) {
        if (isMute) {
          if (media.volume == 0) {
            if (item.pip.currentVol > 0) {
              media.volume = item.pip.currentVol;
            } else {
              media.volume = 0.5;
              item.pip.currentVol = 0.5;
            }
          } else {
            media.volume = 0;
          }
        } else {
          media.volume = item.pip.currentVol;
        }

        //ATUALIZA O ICONE DO VOLUME
        if (media.volume == 1) {
          item.pip.currentVolIcon = "Volume2Icon";
        } else if (media.volume == 0) {
          item.pip.currentVolIcon = "VolumeXIcon";
        } else if (media.volume <= 0.4) {
          item.pip.currentVolIcon = "VolumeIcon";
        } else if (media.volume >= 0.5) {
          item.pip.currentVolIcon = "Volume1Icon";
        }
      }
    },
  },
};
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  display: block;
  color: white;
}
.is-open {
  height: 60px;
  padding: 10px;
}
.skin-light {
  background-color: #1990ff;
}
.skin-dark {
  background-color: #121212;
}
</style>
