<template>
  <div 
    class="tool-bar"
    :class="[{ 'is-open': show }, lightMode ? 'skin-light' : 'skin-dark']"
  >
    <b-card-text class="mb-0">
      {{ `${currentLabel} ${currentValue}%` }}
    </b-card-text>
    <b-progress
      :value="currentValue"
      max="100"
      :variant="currentValue < 100 ? 'warning' : 'success'"
      class="progress-bar-dark"
      animated
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BProgress } from 'bootstrap-vue'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig()
    return {
      skin
    }
  },
  components: {
    BProgress,
  },
  data() {
    return {
      toolBarName: 'progress-bar'
    }
  },
  computed: {
    ...mapState({
      currentToolBar: state => state.toolbar.currentToolBar,
      currentValue: state => state.toolbar.progress.value,
      currentLabel: state => state.toolbar.progress.text
    }),
    lightMode() {
      return this.skin == 'light' ? true : false;
    },
    show() {
      return this.currentToolBar == this.toolBarName ? true : false;
    }
  }
}
</script>

<style scoped>
.tool-bar {
  transition: height 0.3s;
  overflow: hidden;
  height: 0px;
  width: 100%;
  display: block;
  color: white;
}
.is-open {
  height: 60px;
  padding: 10px;
}
.skin-light {
  background-color: #1990FF;
}
.skin-dark {
  background-color: #121212;
}
</style>